import { Elements } from '@stripe/react-stripe-js';
import {
  Appearance,
  StripeElementsOptions,
  loadStripe,
} from '@stripe/stripe-js';
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import { PaymentPage } from '../../../shared/components/PaymentPages/PaymentPage';
import {
  BetaAgreementLink,
  BetaCheckboxesWrapper,
  BetaCheckoutBetaLogo,
  BetaCheckoutFormConteiner,
  BetaCheckoutHeaderContainer,
  BetaCheckoutLogoWrp,
  BetaSpotsLeftWrapper,
  BetaSubtotalWrapper,
  BetaSummatyWrapper,
} from './styled';
import * as variables from '../../../constants/styles';
import * as urls from '../../../constants/urls';
import { useSubscriptionCheckout } from '../hooks/useSubscriptionCheckout';
import { CustomAlert } from '../../../shared/components/CustomAlert/CustomAlert';
import { PinkLoader } from '../../../shared/components/Loaders/PinkLoader/PinkLoader';
import {
  LittleBlackText,
  PrimaryWhiteButton,
  SimpleBlackText,
  TextBlackLarge,
  TitleBlack,
} from '../../../shared/styles';
import {
  MainLogoNoWhiteSpace,
} from '../../../shared/icons';
import { CommonPriceInputReadOnly } from '../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { HrLine } from '../../../shared/styles/Elements';
import { CurrencyTypes } from '../../../shared/types';
import { CommonCheckbox } from '../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { ErrorMessage } from '../../../shared/components/styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import { fetchLeftSpots } from '../../Creator/redux/userCreator/subscriptionSlice';
import { fetchPlans } from '../../Creator/redux/userCreator/plansSlice';

const appearance: Appearance = {
  theme: 'stripe',
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');

type BetaCheckoutAgreementProps = {
  checkboxError: boolean;
  subscriptionTerms: boolean;
  setSubscriptionTerms: Dispatch<SetStateAction<boolean>>;
  subscriptionPolicy: boolean;
  setSubscriptionPolicy: Dispatch<SetStateAction<boolean>>;
};

const BetaCheckoutAgreement: React.FC<BetaCheckoutAgreementProps> = ({
  checkboxError,
  subscriptionPolicy,
  subscriptionTerms,
  setSubscriptionPolicy,
  setSubscriptionTerms,
}) => {
  const { t } = useTranslation('sales');
  const vacantSpots = useTypedSelectorCreator((state) => state.subscription.spots);
  const isLoading = useTypedSelectorCreator((state) => state.subscription.loading);
  const error = useTypedSelectorCreator((state) => state.subscription.error);
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  useEffect(() => {
    dispatch(fetchLeftSpots(getToken));
  }, []);

  return (
    <div>
      <BetaCheckboxesWrapper>
        <CommonCheckbox
          error={(checkboxError && !subscriptionTerms)}
          isChecked={subscriptionTerms}
          name="subscriptionTerms"
          handleChange={() => setSubscriptionTerms((prev) => !prev)}
          label={(
            <LittleBlackText style={{ display: 'block' }}>
              <Trans t={t} i18nKey="subacriptionTermsOne">
                I have read and acknowledge that subscription plans last 12 months
                and automatically renew unless cancelled. I agree to the
                {' '}
                <BetaAgreementLink href={urls.termsUrl} target="_blank">Subscription Terms</BetaAgreementLink>
                .
              </Trans>
            </LittleBlackText>
          )}
        />
        <CommonCheckbox
          error={(checkboxError && !subscriptionPolicy)}
          isChecked={subscriptionPolicy}
          name="subscriptionPolicy"
          handleChange={() => setSubscriptionPolicy((prev) => !prev)}
          label={(
            <LittleBlackText style={{ display: 'block' }}>
              <Trans t={t} i18nKey="subacriptionTermsTwo">
                I have read and acknowledge the Annual Subscription Auto-Renewal
                Policy and Cancellation Policy
                {' '}
                <BetaAgreementLink href={urls.termsUrl} target="_blank">Subscription Terms</BetaAgreementLink>
                .
              </Trans>
            </LittleBlackText>
          )}
        />
        {checkboxError && (
        <ErrorMessage>{t('aggrementError')}</ErrorMessage>
        )}
      </BetaCheckboxesWrapper>
      <BetaSpotsLeftWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {isLoading ? <PinkLoader size="xs" /> : (
          <LittleBlackText fontWeight={600} style={{ display: 'block' }}>
            {vacantSpots}
            {' '}
            spots left
          </LittleBlackText>
        )}
      </BetaSpotsLeftWrapper>
    </div>
  );
};

export const BetaCheckoutForm: React.FC = () => {
  const { t } = useTranslation(['basicComponents', 'sales', 'signInBeta']);
  const {
    isLoading, error, setError, clientSecretKey, getClientSecretKey,
  } = useSubscriptionCheckout();
  const [tryAgainVisible, setTryAgainVisible] = useState(false);
  const [subscriptionPolicy, setSubscriptionPolicy] = useState(false);
  const [subscriptionTerms, setSubscriptionTerms] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const plans = useTypedSelectorCreator((state) => state.plans.plans);
  const isLoadingPlans = useTypedSelectorCreator((state) => state.plans.loading);
  const errorPlans = useTypedSelectorCreator((state) => state.plans.error);
  const dispatch = useCreatorAppDispatch();
  const { getToken } = useAuth();

  const betaPlan = plans?.find((item) => item.name.toLowerCase().includes('beta'));

  const handleGetClientSecretKey = () => {
    if (betaPlan?.price?.id) {
      getClientSecretKey(betaPlan?.price?.id);
    }
  };
  const onTryAgain = () => {
    setTryAgainVisible(false);
    handleGetClientSecretKey();
  };
  const canBeSubmited = (): boolean => {
    const res = subscriptionPolicy && subscriptionTerms;
    if (!res) {
      setCheckboxError(true);
      return res;
    }
    setCheckboxError(false);
    return res;
  };

  useEffect(() => {
    handleGetClientSecretKey();
  }, [betaPlan]);

  useEffect(() => {
    dispatch(fetchPlans(getToken));
  }, []);

  const options: StripeElementsOptions = {
    clientSecret: clientSecretKey,
    appearance,
  };

  return (
    <BetaCheckoutFormConteiner style={{ borderRadius: '0px' }}>
      <BetaCheckoutHeaderContainer>
        <BetaCheckoutLogoWrp>
          <MainLogoNoWhiteSpace />
          <BetaCheckoutBetaLogo>beta</BetaCheckoutBetaLogo>
        </BetaCheckoutLogoWrp>
        <BetaSummatyWrapper>
          <TitleBlack style={{ fontWeight: 600 }}>
            {t('signInBeta:annualSubscription')}
          </TitleBlack>
          {errorPlans && <ErrorMessage style={{ display: 'block' }}>{errorPlans}</ErrorMessage>}
          <HrLine style={{ marginTop: '20px' }} />
          <BetaSubtotalWrapper>
            <SimpleBlackText fontWeight={500}>
              {t('sales:subTotal')}
            </SimpleBlackText>
            <SimpleBlackText fontWeight={500}>
              {isLoadingPlans ? <PinkLoader size="xs" /> : (
                <CommonPriceInputReadOnly
                  currenyType={CurrencyTypes[betaPlan?.price?.currency || 'USD']}
                  currencyLetters={betaPlan?.price?.currency || 'USD'}
                  value={betaPlan?.price?.amount || 0}
                />
              )}
            </SimpleBlackText>
          </BetaSubtotalWrapper>
          <HrLine />
          <BetaSubtotalWrapper>
            <TextBlackLarge fontWeight={500}>{t('signInBeta:dueToday')}</TextBlackLarge>
            <TextBlackLarge fontWeight={600}>
              {isLoadingPlans ? <PinkLoader size="xs" /> : (
                <CommonPriceInputReadOnly
                  currenyType={CurrencyTypes[betaPlan?.price?.currency || 'USD']}
                  currencyLetters={betaPlan?.price?.currency || 'USD'}
                  value={betaPlan?.price?.amount || 0}
                />
              )}
            </TextBlackLarge>
          </BetaSubtotalWrapper>
        </BetaSummatyWrapper>
      </BetaCheckoutHeaderContainer>
      {isLoading && <PinkLoader />}
      {clientSecretKey && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentPage
            loader={{
              color: 'white',
              size: 'xs',
            }}
            styles={{
              paymentForm: {
                padding: '0px',
              },
              button: {
                borderRadius: '51px',
                textTransform: 'uppercase',
                fontWeight: 600,
                lineHeight: '150%',
                fontSize: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '13px 28px',
                height: 'fit-content',
                whiteSpace: 'pre',
                color: variables.colorGreyLight,
                backgroundImage:
                  'linear-gradient(300deg, #E6934F 0%, #E186B0 49.82%, #78C0EA 100%)',
                boxShadow: '-5px -5px 10px #FFFFFF, 5px 5px 10px #E8E8E8',
              },
            }}
            canBeSubmited={canBeSubmited}
            returnUrl={`${window.location.origin}${urls.paymentMethodUrl}${urls.subscriptionSuccess}`}
            agreement={(
              <BetaCheckoutAgreement
                checkboxError={checkboxError}
                subscriptionPolicy={subscriptionPolicy}
                subscriptionTerms={subscriptionTerms}
                setSubscriptionPolicy={setSubscriptionPolicy}
                setSubscriptionTerms={setSubscriptionTerms}
              />
          )}
          />
        </Elements>
      )}
      {tryAgainVisible && !isLoading && (
        <PrimaryWhiteButton
          type="button"
          onClick={onTryAgain}
          disabled={isLoading}
          style={{ color: 'red', width: '180px', minWidth: '180px' }}
        >
          {t('tryAgain')}
        </PrimaryWhiteButton>
      )}

      <CustomAlert
        isOpenWindow={!!error}
        message={error}
        title="Payment finished with errors"
        onClose={() => {
          setTryAgainVisible(true);
          setError('');
        }}
        onClick={() => {
          setTryAgainVisible(true);
          setError('');
        }}
        type="error"
      />
    </BetaCheckoutFormConteiner>
  );
};
